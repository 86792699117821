import React from 'react'
import { graphql } from 'gatsby'

import { Page } from '../components/page'

export default function GdprPage({ data, location }) {
  const { frontmatter, html } = data.markdownRemark
  const { title, description } = frontmatter
  return (
    <Page
      title={title}
      description={description}
      html={html}
      location={location}
      showSidebar
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/ochrana-osobnich-udaju/" } }) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`
