import React from 'react'
import { Helmet } from 'react-helmet'

import { BaseLayout } from './base-layout'
import { Sidebar } from './sidebar'
import { absoluteUrl } from '../utils/url'

export function Page({
  title,
  description,
  content,
  html,
  showSidebar,
  location = {},
}) {
  return (
    <BaseLayout sidebar={showSidebar && <Sidebar />}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={absoluteUrl(location.pathname)} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <article className="no-offset">
        <h1>{title}</h1>
        {content || <section dangerouslySetInnerHTML={{ __html: html }} />}
      </article>
    </BaseLayout>
  )
}
